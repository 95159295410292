import { ErrorHandler, NgModule, Type } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { A3dnContractsLibModule, ContractCardService, NewContractService } from "a3dn-contracts-lib";
import { RenewContractComponent, RenewStateService } from "a3dn-renewals-lib";
import { NewSettlementComponent } from "a3dn-settlements-lib";
import { A3dnPanelResponseService, ModeEnum, NewMessageBody, PanelWidth, Paneltype } from "a3dn-shared-lib";
import { NewSmsComponent } from "a3dn-sms-lib";
import { NewUnpaidCompanyComponent } from "a3dn-unpaid-lib";
import { Observable } from "rxjs";
import { NewClaimModule, TicketNewMessageComponent } from "ticket-domain-lib";
import { GlobalErrorHandler } from "./global-error-handling/global-error-handler";
import { NewContractAssistantComponent } from "a3dn-contractassistant-lib";

@NgModule({
	imports: [
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		A3dnContractsLibModule,
		NewClaimModule,
	],
	providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class CoreModule {
	constructor(
		contractService: ContractCardService,
		renewService: RenewStateService,
		newContractService: NewContractService,
		private a3dnPanel: A3dnPanelResponseService
	) {
		renewService.stopFollowUpContract = contractService.editContractFollowUpStatus.bind(contractService);

		renewService.renewContract = newContractService.renewContract.bind(newContractService);
		a3dnPanel.openNewMessagePanel = (message) =>
			a3dnPanel.openEditPanel<NewMessageBody, string>({
				componentName: TicketNewMessageComponent,
				addOrEditMode: ModeEnum.add,
				panelWidth: PanelWidth.meduim,
				body: message,
			});

		a3dnPanel.openPanel = this.openPanel.bind(this);
	}

	openPanel<T>(panel: Paneltype, value: T, width?: PanelWidth): Observable<string> {
		return this.a3dnPanel.openEditPanel({
			componentName: PANEL_COMPONENT_MAP[panel],
			body: value,
			panelWidth: width || PanelWidth.meduim,
		});
	}
}

const PANEL_COMPONENT_MAP: { [key in Paneltype]: Type<any> } = {
	"new-contract": NewContractAssistantComponent,
	"new-ticket": TicketNewMessageComponent,
	"new-sms": NewSmsComponent,
	"new-unpaid": NewUnpaidCompanyComponent,
	"new-settlement": NewSettlementComponent,
	"new-renewal": RenewContractComponent,
};
