import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as signalR from "@aspnet/signalr";
import { A3dnDeleteAlertService } from "a3dn-shared-lib";

@Injectable({
	providedIn: "root",
})
export class AppSignalRService {
	private baseUrl?: string;

	private hubConnection?: signalR.HubConnection;

	startTimeout: any;
	constructor(private a3dnAlertPanel: A3dnDeleteAlertService) {}
	start() {
		let token = localStorage.getItem("jwt");
		if (!token) return;
		try {
			this.startConnection(token);
		} catch (error) {}
		this.addWebSocketListener();
		if (this.startTimeout) clearTimeout(this.startTimeout);
		this.startTimeout = setTimeout(() => {
			this.start();
		}, 600_000);
	}

	private startConnection(token: string) {
		this.stop();
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(this.baseUrl + "/nouveaudeploiementhub", {
				accessTokenFactory: () => token,
			})
			.build();
		this.hubConnection.start().catch((err) => console.log("Error while starting connection: " + err));
	}

	private addWebSocketListener() {
		this.hubConnection?.on("nouveau-deploiement", (data) => {
			this.handleNewRelease();
		});

		this.hubConnection?.onclose(() => {
			this.start();
		});
	}

	private handleNewRelease() {
		this.a3dnAlertPanel
			.openModal({
				alertDescription:
					"Une nouvelle version vient d'être déployée. Cliquer sur le bouton Rafraîchir afin de mettre à jour votre navigateur.",
				title: "Nouvelle version",
				confirmButton: "Rafraîchir",
				buttonColor: "primary",
				cancelButton: "Annuler",
			})
			.subscribe((response) => {
				if (response) location.reload();
			});
	}
	setBaseUrl(baseUrl: string) {
		this.baseUrl = baseUrl;
	}

	stop() {
		this.hubConnection?.stop();
	}
}
